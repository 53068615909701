import clipboard from "../../assets/clipboard.svg";
import ProcessDrivenSection from "./ProcessDrivenSection";
import InsdustryDataSection from "./InsdustryDataSection";
import SalesTeamSection from "./SalesTeamSection";
import FooterSection from "../Common/FooterSection";
import Navbar from "../Common/Navbar";
import CompanyDriven from "../Common/CompanyDriven";
import { setShowSMenu } from "../../reducer/slices/showMenuSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../fonts.css";
import FadeIn from "react-fade-in";
import "./home.css";
import Testimonial from "../../Components/Testimonial";

function Home() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setShowSMenu(false));
    }, []);

    return (
        <div className={`min-h-[100vh]  h-full overflow-x-hidden`}>
            {/* secction 1 hero section */}
            <section className="heroSectionWrapper flex justify-center bg-[#0E1824]  ">
                {/* navbar */}
                <Navbar />
                <FadeIn>
                    <main className="flex pt-[200px]  homePage_mainSec flex-row h-fit justify-between  ml-[55px] mr-[80px] items-start">
                        {/* left part  */}
                        <div className=" flex-1 homePage_mainLeft flex flex-col gap-[7px]">
                            {/* <p
                                style={{ fontFamily: "Roboto-bold" }}
                                className="text-[#42BDAC] font-[700] text-[21px] tracking-[6px]	 "
                            >
                                SALES PIPELINE BUILDERS
                            </p> */}
                            <p
                                style={{
                                    fontFamily: "Poppins-bold",
                                }}
                                className="font-[700] main_text nowarp-text text-[28px] sm:text-[30px] md:text-[32px]  xl:text-[41px] text-[#FFFFFF] w-[100%]  "
                            > 
                                Anxious you may miss <br /> 2025 revenue target?
                                {/* <span className="text-[#42BDAC] ">.</span> */}
                            </p>

                            <div className="flex flex-col gap-[17px] mt-3 homePage_Btn_container   ">
                                <p
                                    style={{ fontFamily: "Poppins" }}
                                    className="text-[#FFFFFF] subHeading_text nowarp-text text-[18px] md:text-[22px] w-full font-[600] "
                                >
                                    Get 8-17 demos from cold calling. <br /> Join our outbound prospecting program.
                                     <br /> 
                                    
                                </p>
                                <Link to={"/YesPitchMe"}>
                                    {" "}
                                    <button
                                        style={{
                                            fontFamily: "Poppins-bold",
                                        }}
                                        className="w-[180px] h-[50px] rounded-[20px] bg-[#42BDAC] text-[18px] font-[700] text-[black] "
                                    >
                                        Start Selecting
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* right part  */}
                        {/* <div className="w-[45%] homePage_main_right flex flex-col items-center    gap-[20px]">
                            <img src={clipboard} alt="clipboard" />
                            <p
                                style={{ fontFamily: "Avram-semibold" }}
                                className="text-[white] text-center home_sec1_para font-[500] tracking-tight text-[22px]  w-[480px] "
                            >
                                "Grow10X prospecting system is out of the box.{" "}
                                <br />
                                One sourced lead turned into a $525k deal.{" "}
                                <br />
                                They outperformed our internal SDRs by 2.8X.{" "}
                                <br />
                                Best outbound system for B2B tech."
                            </p>
                            <p
                                style={{
                                    fontFamily: "Poppins-bold",
                                }}
                                className="font-[500] text-opacity-90  text-[26px] text-center mt-[-15px] text-white "
                            >
                                Ben Stepensky <br />{" "}
                                <span
                                    style={{
                                        fontFamily: "sebino-regular",
                                    }}
                                    className="font-[500] text-[20px] opacity-70"
                                >
                                    {" "}
                                    SDR Director, EasySend
                                </span>
                            </p>
                        </div> */}

                        <div className=" homePage_main_right flex flex-col items-center justify-center gap-[20px] mt-4 w-fit">
                            <Testimonial />
                        </div>
                    </main>
                </FadeIn>
            </section>

            <CompanyDriven />

            <ProcessDrivenSection />

            <InsdustryDataSection />

            <SalesTeamSection
                styling={"text-[#D9D9D9]"}
                para={
                    "Hiring an internal staff or external agency with limited proven case is risky and expensive. We minimize your risk and maximize your revenue by providing a system and process than has proven track record."
                }
                fontFamily={"Avram-semibold"}
                showAboutCeo={true}
            />

            <FooterSection />
        </div>
    );
}
export default Home;
